html {
  color: #c7b59d;
  background-color: #000;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

body.gamemode {
  overflow: hidden;
}

canvas {
  opacity: 0;
}

canvas.visible {
  opacity: 1;
  transition: opacity .7s;
}

.levelSelectionDiv {
  max-width: 900px;
  opacity: 1;
  margin: auto;
  transition: opacity .7s;
}

.levelSelectionButton {
  text-align: center;
  width: 120px;
  color: #c7b59d;
  background-color: #282d33;
  border: 4px solid #c7b59d;
  margin: 10px;
  padding: 27px 0;
  font-size: 300%;
  transition: all .15s linear;
  display: inline-block;
}

.levelSelectionButton:hover {
  color: #282d33;
  background-color: #c7b59d;
}

/*# sourceMappingURL=index.3ed10288.css.map */
